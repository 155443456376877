<template>
  <HideWhileSpinnerActive class="text-white">
    <!-- Empty state -->
    <div v-if="!invites.length && !teams.length" class="md:max-w-xl m-auto">
      <img
        class="object-scale-down h-64 w-full"
        src="@/assets/images/Screenshot_from_2020-10-13_14-30-38.png"
        alt=""
      />
      <div class="text-white text-center">
        <h3 class="heading-1 whitespace-pre-line" v-text="t('empty_title')" />
        <p class="text-sm px-6 py-6 md:px-24">
          <i18n-t keypath="views.Team.empty_message" />
        </p>
      </div>
    </div>

    <h1
      v-if="invites.length"
      class="text-white font-semibold text-2xl px-2 whitespace-pre-line pt-6"
    >
      <i18n-t keypath="views.Team.invites" />
    </h1>
    <template v-for="invite in invites" :key="invite.team?.id">
      <div
        class="mt-4 relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
      >
        <div class="min-w-0 flex-1">
          <p class="text-sm font-medium text-gray-900">
            {{ invite.team?.name }}
          </p>
          <p class="truncate text-sm text-gray-500">
            {{ longDateToHumanReadable(invite.created_at) }}
          </p>
        </div>
        <button @click.prevent="acceptInvite(invite)" class="button">
          <i18n-t keypath="views.Team.invites_accept" />
        </button>
        <div
          @click="declineInvite(invite)"
          class="text-scorelitred hover:text-orange inline-block transition cursor-pointer"
        >
          <i18n-t keypath="views.Team.invites_decline" />
        </div>
      </div>
    </template>

    <template v-for="team in teams" :key="team.id">
      <h1 class="text-white uppercase text-2xl font-extrabold text-center">
        {{ team.name }}
      </h1>

      <div>
        <h2 class="text-white uppercase text-xl font-extrabold">
          <i18n-t keypath="views.Team.trainees" />
        </h2>
        <div class="pt-3 grid grid-cols-1 gap-4 sm:grid-cols-2">
          <div
            v-for="trainee in team.trainees"
            :key="trainee.id"
            class="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
          >
            <div class="flex-shrink-0">
              <img
                class="h-10 w-10 rounded-full"
                :src="trainee.profile_image"
                alt=""
              />
            </div>
            <div class="min-w-0 flex-1">
              <p class="text-sm font-medium text-gray-900">
                {{ trainee.name }}
              </p>
            </div>
          </div>
        </div>

        <div v-if="team.reviews.length !== 0">
            <div class="px-2 pt-10">
                <h2 class="text-white uppercase text-xl font-extrabold" v-text="t('reviews')" />
            </div>
            <div class="flex flex-wrap">
                <ReviewItem v-for="review in team.reviews" :key="review.id" :review="review" />
            </div>
        </div>

        <div v-if="team.data.length !== 0">
            <div class="px-2 pt-10">
                <h2 class="text-white uppercase text-xl font-extrabold" v-text="t('games')" />
            </div>
            <div class="flex flex-wrap">
                <router-link v-for="match in team.data" :key="match.id" :to="{ name: 'match.show', params: {id: match.id}}" class="w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 px-3 py-3">
                    <MatchItem  :match="match" />
                </router-link>
            </div>
        </div>
      </div>
    </template>
  </HideWhileSpinnerActive>
</template>

<script>
import MatchItem from "@/components/match/IndexItem";
import ReviewItem from "@/components/review/IndexItem";
import useI18n from "@/modules/globalI18n";
import { indexInvites } from "@/helpers/api/InviteHelper";
import { acceptInvite, declineInvite } from "@/helpers/api/InviteHelper";
import { indexTeam } from "@/helpers/api/TeamHelper";
import { longDateToHumanReadable } from "@/helpers/DateHelper";
import {
  startSpinner,
  stopSpinner,
} from "@/helpers/storage/loadingSpinnerHelper";
import HideWhileSpinnerActive from "@/wrappers/HideWhileSpinnerActive";

export default {
  setup() {
    return useI18n({
      viewPrefix: "Team",
    });
  },
  async created() {
    startSpinner();
    await Promise.all([this.resetInvitesList(), this.resetTeamsList()]);
    stopSpinner();
  },
  data() {
    return {
      invites: [],
      teams: [],
      state: {
        resetInvitesListIncrementor: 1,
        resetTeamsListIncrementor: 1,
      },
    };
  },
  methods: {
    indexInvites,
    indexTeam,
    longDateToHumanReadable,
    acceptInvite(invite) {
      acceptInvite(invite.uuid).then(() => {
        this.resetInvitesList();
        this.resetTeamsList();
      });
    },
    declineInvite(invite) {
      declineInvite(invite.uuid).then(() => {
        this.resetInvitesList();
      });
    },
    async resetInvitesList() {
      this.invites = (await indexInvites()).invites;
    },
    async resetTeamsList() {
      this.teams = (await indexTeam()).data;
    },
  },
  components: {
    HideWhileSpinnerActive,
    ReviewItem,
    MatchItem,
  },
};
</script>
