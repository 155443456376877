import axios from "axios";


export function indexInvites(page = 1) {
    const url = `v1/trainee/invite?page=${page}`

    return axios.get(url).then(response => response.data)
}

export function acceptInvite(inviteUuid) {
    const url = `v1/trainee/invite/${inviteUuid}?_method=patch`

    return axios.post(url, { accept: true }).then(response => response.data)
}

export function declineInvite(inviteUuid) {
    const url = `v1/trainee/invite/${inviteUuid}?_method=patch`
    
    return axios.post(url, { accept: false }).then(response => response.data)
}